import React from "react";
import * as styles from "../styles/news.module.css";
import { StaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

const NewsCoponent = ({ data }) => {
 return (
  <StaticQuery
   query={graphql`
    query {
     allContentfulNews(sort: { fields: createdAt, order: DESC }) {
      nodes {
       title
       url
       excerpt
       updatedAt(formatString: "DD.MM.yy")
       backgroundImage {
        fluid {
         ...GatsbyContentfulFluid
        }
       }
      }
     }
    }
   `}
   render={(data) => {
    const filteredNews = data.allContentfulNews.nodes.filter(
     (post, index) => index < 4 && index > 0
    );
    const { nodes } = data.allContentfulNews;

    return (
     <section className={styles.section}>
      <div className={styles.titleRow}></div>

      <div className={styles.latestsNewsRow}>
       <div className={styles.latestsBox}>
        <div className={styles.imgWrapper}>
         <Img fluid={nodes[0].backgroundImage.fluid} alt="" />
        </div>
        <div className={styles.textWrapper}>
         <h4>{nodes[0].title}</h4>
         <p>data: {nodes[0].updatedAt}</p>
         <p>{nodes[0].excerpt}</p>
         <Link
          className={styles.moreButton}
          to={`/aktualnosci/${nodes[0].url}`}
         >
          WIĘCEJ
         </Link>
        </div>
       </div>
      </div>
      <div className={styles.latestThreeContainer}>
       {filteredNews.map((news, index) => {
        return (
         <div key={_.uniqueId("key_")} className={styles.singleNewsBox}>
          <h4 className={styles.singleNewsTitle}>{news.title}</h4>
          <p className={styles.singleNewsDate}>data: {news.updatedAt}</p>
          <p className={styles.singleNewsText}>
           {news.excerpt}
           <Link to={`/aktualnosci/${news.url}`}>
            <FontAwesomeIcon className={styles.arrow} icon={faArrowRight} />
           </Link>
          </p>
         </div>
        );
       })}
      </div>
     </section>
    );
   }}
  />
 );
};

//
export default NewsCoponent;
