import React from "react";
import * as styles from "../styles/ourValues.module.css";

const OurValuesComponent = () => {
 return (
  <section className="section-background">
   <div className={styles.sectionContainer}>
    <div className={styles.titleWrapper}>
     <h2>Nasze inicjatywy</h2>
    </div>
    <div className={styles.descWrapper}>
     <p>
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. Lorem ipsum
      dolor sit amet, consetetur sadipscing
     </p>
    </div>
   </div>
  </section>
 );
};

export default OurValuesComponent;
