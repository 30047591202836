import React, { useState } from "react";
import * as styles from "../styles/newsletter.module.css";
import newsLetterimg from "../images/Newsletter.png";

const NewsletterComponent = () => {
 const [inputValue, setInputValue] = useState("");
 const [sended, setSended] = useState(false);

 const handleSubmit = (e) => {
  e.preventDefault();
  setInputValue("");
  setSended(true);
 };

 const handleChange = (e) => {
  setInputValue(e.target.value);
 };

 return (
  <section className="section-background">
   <div className={styles.newsletterContainer}>
    <div className={styles.imgWrapper}>
     <img
      src={newsLetterimg}
      alt="Uśmiechnięta kobieta pokazująca kciuk w górę"
     />
    </div>
    <div className={styles.newsletterWrapper}>
     <h5>NEWSLETTER</h5>
     <form className={styles.newsForm} onSubmit={handleSubmit}>
      <label htmlFor="email">
       <input
        required
        className={styles.emailInput}
        onChange={handleChange}
        id="email"
        type="email"
        pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}"
        placeholder="email"
        value={inputValue}
       />
      </label>
      <input className={styles.subscribe} type="submit" value="SUBSKRYBUJ" />
     </form>
    </div>
    {sended && <div>wysłano</div>}
   </div>
  </section>
 );
};

export default NewsletterComponent;
