import React, { useEffect, useState, useRef } from "react";
import * as styles from "../styles/successes.module.css";
import CountUp from "react-countup";

const SuccessesComponent = () => {
  const [isInView, setIsInView] = useState(false);
  const countUpRef = useRef();

  useEffect(() => {
    const counterHeight = countUpRef.current.getBoundingClientRect();
    const handleScroll = () => {
      counterHeight.y < window.scrollY ? setIsInView(true) : setIsInView(false);
    };
    document.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <section className={styles.sectionContainer}>
      {console.log(isInView)}
      <div className={styles.row}>
        <div className={styles.titleWrapper}>
          <h2 ref={countUpRef}>Sukcesy</h2>
        </div>
      </div>
      <div className={`${styles.row} ${styles.rowWrapp}`}>
        <div className={styles.successesContainer}>
          <div className={styles.descWrapper}>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              errat.
            </p>
          </div>
        </div>
        <div className={styles.countersWrapper}>
          <div className={styles.counterWrapper}>
            {isInView && (
              <CountUp
                start={0}
                end={200}
                duration={3}
                separator=" "
                delay={0.75}
              />
            )}
            <span className={styles.counterDesc}>zrealizowanych projektów</span>
          </div>
          <div className={styles.counterWrapper}>
            {isInView && (
              <CountUp
                start={0}
                end={20}
                duration={3}
                separator=" "
                delay={0.75}
              />
            )}
            <span className={styles.counterDesc}>lat współpracy</span>
          </div>
          <div className={styles.counterWrapper}>
            {isInView && (
              <CountUp
                start={0}
                end={12}
                duration={3}
                separator=" "
                delay={0.75}
              />
            )}
            <span className={styles.counterDesc}>aktywnych programów</span>
          </div>
          <div className={styles.counterWrapper}>
            {isInView && (
              <CountUp
                start={0}
                end={1000}
                duration={3}
                separator=" "
                delay={0.75}
              />
            )}
            <span className={styles.counterDesc}>pomysłów na przyszłość</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuccessesComponent;
