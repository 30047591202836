import React from "react";
import * as styles from "../styles/ourValuesIcons.module.css";
import _ from "lodash";
import boxesContent from "../assets/OurValuesBoxesContent";

const OurValuesIcons = () => {

  return (
    <section className={styles.sectionValues}>
      {boxesContent.map((boxImg, index) => {
        return (
          <div key={_.uniqueId("key_")} className={styles.valuesBox}>
            <div>
              <img aria-label="hidden" src={boxImg.img} />
            </div>
            <div className={styles.desc}>
              <p>{boxImg.up}</p> 
              <p>{boxImg.down}</p> 
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default OurValuesIcons;
