// extracted by mini-css-extract-plugin
export var counterDesc = "successes-module--counterDesc--4sdfh";
export var counterWrapper = "successes-module--counterWrapper--LhGjF";
export var countersWrapper = "successes-module--countersWrapper--vI8yr";
export var descWrapper = "successes-module--descWrapper--Bsywu";
export var imgWrapper = "successes-module--imgWrapper--snZp2";
export var margin = "successes-module--margin--cieSM";
export var offerBoxContainer = "successes-module--offerBoxContainer--O0QAk";
export var offerBoxWrapper = "successes-module--offerBoxWrapper--QEvfw";
export var row = "successes-module--row--boxqA";
export var rowWrapp = "successes-module--rowWrapp--Oj13d";
export var sectionContainer = "successes-module--sectionContainer--y2G2n";
export var successesContainer = "successes-module--successesContainer--Gp12Z";
export var textWrapper = "successes-module--textWrapper--6QUw3";
export var titleWrapper = "successes-module--titleWrapper--ZQJhc";