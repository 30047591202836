import React from "react";
import * as styles from "../styles/news.module.css";

const NewsTitleComponent = () => {
 return (
  <section id="aktualnosci" className="section-background">
   <div className={styles.sectionContainer}>
    <div className={styles.titleWrapper}>
     <h2>Aktualności</h2>
    </div>
    <div className={styles.descWrapper}>
     <p></p>
    </div>
   </div>
  </section>
 );
};

export default NewsTitleComponent;
