import { useStaticQuery, graphql } from "gatsby";

const MainSliderCaruseleContent = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { eq: "png" }
          relativeDirectory: { eq: "mainSlider" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const content = [
    {
      img: data.allFile.edges[0].node.childImageSharp.fluid,
      up: ",,W ZIELENI",
      alt: "w zieleni widzimy przyszłość",
      down: "WIDZIMY PRZYSZŁOŚĆ''",
    },
    {
      img: data.allFile.edges[1].node.childImageSharp.fluid,
      up: ",,LOREM IPSUM",
      alt: "lorem ipsum dolor amet",
      down: "DOLOR AMET''",
    },
    {
      img: data.allFile.edges[2].node.childImageSharp.fluid,
      up: ",,LOREM IPSUM",
      alt: "lorem ipsum dolor amet",
      down: "DOLOR AMET''",
    },
    {
      img: data.allFile.edges[3].node.childImageSharp.fluid,
      up: ",,LOREM IPSUM",
      alt: "lorem ipsum dolor amet",
      down: "DOLOR AMET''",
    },
  ];

  return content;
};

export default MainSliderCaruseleContent;
