import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import _ from "lodash";
import Layout from "../components/Layout";
import CaruseleComponent from "../components/CaruseleComponent";
// import * as styles from "../styles/index.module.css";
import { LangContext } from "../components/LangContext";
import OfferCarusele from "../components/OfferCarusele";
import "../styles/styles.css";
import CompanyComponent from "../components/CompanyComponent";
import OfferComponent from "../components/OfferComponent";
import OurValuesComponent from "../components/OurValuesComponent";
import OurValuesIcons from "../components/OurValuesIcons";
import TeamComponent from "../components/TeamComponent";
import SpecialOfferComponent from "../components/SpecialOfferComponent";
import SuccessesComponent from "../components/SuccessesComponent";
import NewsletterComponent from "../components/NewsletterComponent";
import ContactUs from "../components/ContactUs";
import NewsComponent from "../components/NewsComponent";
import NewsTitleComponent from "../components/NewsTitleComponent";

// let uniqKey = _.uniqueId("key_");

const IndexPage = () => {
 const [lang, setLang] = useState(true);

 return (
  <LangContext.Provider value={{ lang, pickLang: setLang }}>
   <Layout>
    <CaruseleComponent />
    <CompanyComponent />
    <OfferComponent />
    <OfferCarusele desktop={3} tablet={2} mobile={1} />
    <OurValuesComponent />
    <OurValuesIcons />
    <NewsTitleComponent />
    <NewsComponent />
    <ContactUs />
    <TeamComponent />
    <SpecialOfferComponent />
    <SuccessesComponent />
    <NewsletterComponent />
   </Layout>
  </LangContext.Provider>
 );
};

export default IndexPage;
