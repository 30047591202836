import React from "react";
import * as styles from "../styles/specialOffer.module.css";
import specialOfferImg from "../images/specialOffer.png";

const SpecialOfferComponent = () => {
 return (
  <section className="section-background">
   <div className={styles.sectionContainer}>
    <div className={styles.titleWrapper}>
     <h2>Specjalna oferta</h2>
    </div>
    <div className={styles.descWrapper}>
     <p>
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. Lorem ipsum
      dolor sit amet, consetetur sadipscing
     </p>
    </div>
   </div>

   <div className={styles.offerBoxContainer}>
    <div className={styles.offerBoxWrapper}>
     <div className={styles.imgWrapper}>
      <img src={specialOfferImg} alt="zdjęcia aktywności na powietrzu" />
     </div>
     <div className={styles.textWrapper}>
      <p>
       Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
       eirmod tempor invidunt ut labore et dolore magna aliquyam errat.
      </p>
      <button>WIĘCEJ</button>
     </div>
    </div>
   </div>
  </section>
 );
};

export default SpecialOfferComponent;
