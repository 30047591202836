import React from "react";
import * as styles from "../styles/company.module.css";
import { Link } from "gatsby";

const CompanyComponent = () => {
 return (
  <>
   <div id="o-projekcie" className={styles.sectionContainer}>
    <div className={styles.titleWrapper}>
     <h2>O projekcie</h2>
    </div>
    <div className={styles.descWrapper}>
     <p>
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
      eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
      voluptua. At vero eos et accusam et justo duo dolores. Stet clita kasd
      gubergren, no sea takimata sanctus est Lorem ipsum dolor. sit amet. Lorem
      ipsum dolor sit amet, consetetur sadipscing. elitr, sed diam nonumy eirmod
      tempor invidunt ut labore.
     </p>
    </div>
    <div className={styles.buttonWrapper}>
     <Link to="/o-projekcie">
      <button>WIĘCEJ</button>
     </Link>
    </div>
   </div>
  </>
 );
};

export default CompanyComponent;
