// extracted by mini-css-extract-plugin
export var arrow = "news-module--arrow--CQR+i";
export var descWrapper = "news-module--descWrapper--XDpkK";
export var imgWrapper = "news-module--imgWrapper--4q+Je";
export var latestThreeContainer = "news-module--latestThreeContainer--JJAGr";
export var latestsBox = "news-module--latestsBox--pYF83";
export var latestsNewsRow = "news-module--latestsNewsRow--1+2aD";
export var moreButton = "news-module--moreButton--DXaqW";
export var move = "news-module--move--7lndH";
export var section = "news-module--section--XW26g";
export var sectionContainer = "news-module--sectionContainer--S9WBn";
export var singleNewsBox = "news-module--singleNewsBox--QBfsr";
export var singleNewsDate = "news-module--singleNewsDate--eOuf7";
export var singleNewsText = "news-module--singleNewsText--REVM0";
export var singleNewsTitle = "news-module--singleNewsTitle--g25A8";
export var textWrapper = "news-module--textWrapper--j6ZOI";
export var titleRow = "news-module--titleRow--rbbA7";
export var titleWrapper = "news-module--titleWrapper--aE+IL";