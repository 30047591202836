import React from "react";
import { Carousel } from "react-bootstrap";
import * as styles from "../styles/carusele.module.css";
import Img from "gatsby-image";
import MainSliderCaruseleContent from "../assets/MainSliderCaruseleContent";
import _ from "lodash";

const CaruseleComponent = () => {
 const data = MainSliderCaruseleContent();

 const prevIcon = <span aria-hidden="true" className={styles.prevImg}></span>;

 const nextIcon = <span aria-hidden="true" className={styles.nextImg}></span>;

 return (
  <Carousel
   className={styles.carouselHero}
   prevIcon={prevIcon}
   nextIcon={nextIcon}
  >
   {data.map((item, index) => (
    <Carousel.Item key={_.uniqueId("id_")}>
     <div className={styles.bckgrnd}> </div>
     <Img fluid={item.img} alt={item.alt} />
     <Carousel.Caption>
      <div className={styles.title}>
       <h1>{item.up}</h1>
       <h1>{item.down}</h1>
      </div>
     </Carousel.Caption>
    </Carousel.Item>
   ))}
  </Carousel>
 );
};

export default CaruseleComponent;
