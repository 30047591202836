import React from "react";
import * as styles from "../styles/team.module.css";
import img1 from "../images/team/team_1.png";
import img2 from "../images/team/team_2.png";
import img3 from "../images/team/team_3.png";

const TeamComponent = () => {
  return (
    <section className="section-background">
      <div className={styles.sectionContainer}>
        <div className={styles.titleWrapper}>
          <h2>Zespół</h2>
        </div>
        <div className={styles.descWrapper}>
          <p>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. Lorem
            ipsum dolor sit amet, consetetur sadipscing
          </p>
        </div>
      </div>
      <section className={styles.teamWrapper}>
        <div className={styles.teamContainer}>
          <div className={styles.teamBox}>
            <img src={img1} alt="" />
            <div className={styles.boxHover}>
              <h4>Lorem ipsum</h4>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed.
                Lorem ipsum dolor sit amet, consetetur sadipscing
              </p>
            </div>
          </div>
          <div className={styles.teamBox}>
            <img src={img2} alt="" />
            <div className={styles.boxHover}>
              <h4>Lorem ipsum</h4>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed.
                Lorem ipsum dolor sit amet, consetetur sadipscing
              </p>
            </div>
          </div>
          <div className={styles.teamBox}>
            <img src={img3} alt="" />
            <div className={styles.boxHover}>
              <h4>Lorem ipsum</h4>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed.
                Lorem ipsum dolor sit amet, consetetur sadipscing
              </p>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default TeamComponent;
